import { Link } from "gatsby"
import styled from "styled-components"
import { theme } from "UI/theme.ts"

export const StyledPlanet = styled.img`
  width: calc(60vw - 1rem);
  height: auto;
  transform: scale(0.8);
  transition: 0.3s 0.1s ease-in-out;
`

export const DashedOutline = styled.div`
  border-radius: 50%;
  border: 1px dashed ${theme.palette.neutral.light};
  position: absolute;
  height: ${({ radius }) => radius}vw;
  width: ${({ radius }) => radius}vw;
  top: calc(50% - ${theme.textStyles.mobilePlanetLabel.fontSize}px);
  left: 50%;
  z-index: 3;
  transform: translate3d(-50%, -50%, 0);
  pointer-events: none;
`

export const IndicatorWrapper = styled.div`
  position: absolute;
  top: calc(100% + 3vh);
  width: 100%;
  left: 0;
  border-top: 1px solid #776f69;
  border-bottom: 1px solid #776f69;
  height: 7vh;

  .swiper-container,
  .swiper-wrapper,
  .swiper-slide {
    height: 100%;
  }

  .swiper-slide-active {
    background-color: #e0492e;
    color: #1a1c10;
  }
`

export const IndicatorLink = styled(Link)`
  background-color: ${({ isActive }) => isActive && "#e0492e"};
  color: ${({ isActive }) => (isActive ? "#1a1c10" : "white")};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-transform: uppercase;
  color: white;
  text-align: center;
`
