import React from "react"
import { IndicatorLink, IndicatorWrapper } from "./styles"
import { Swiper, SwiperSlide } from "swiper/react"
import { getLangFromPath } from "utils"
import navigation from "src/layouts/navigation"
import "swiper/css"

const IndicatorSwiper = ({ controller, onSwiper }) => {
  return (
    <IndicatorWrapper>
      <Swiper
        parallax={true}
        loop
        loopAdditionalSlides={1}
        slidesPerView={3}
        centeredSlides={true}
        controller={controller}
        onSwiper={onSwiper}
        style={{ height: "100%" }}
      >
        {Object.keys(navigation).map(planet => (
          <SwiperSlide key={planet}>
            <IndicatorLink>
              {navigation[planet][getLangFromPath()]?.label}
            </IndicatorLink>
          </SwiperSlide>
        ))}
      </Swiper>
    </IndicatorWrapper>
  )
}

export default IndicatorSwiper
