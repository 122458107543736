import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Parallax, Navigation, Controller } from "swiper"
import "swiper/css"
import "swiper/css/navigation"
import "src/swiper_override.css"
import * as S from "./styles"
import Box from "components/Box"
import navigation from "src/layouts/navigation"
import Typography from "components/Typography"
import { getLangFromPath } from "utils"
import { theme } from "UI/theme.ts"
import { Link, navigate } from "gatsby"

SwiperCore.use([Parallax, Navigation, Controller])

// TODO: check implementation
let vh = typeof window != "undefined" ? window.innerHeight * 0.01 : 0

if (typeof document != "undefined") {
  document.documentElement.style.setProperty("--vh", `${vh}px`)
}

const isPlanetImage = planet => navigation[planet].mobilePlanet.length > 0

const LinkOrA = ({ url, children }) => {
  if (url?.includes("http")) {
    return (
      <a href={url} style={{ textDecoration: "none" }}>
        {children}
      </a>
    )
  } else {
    return (
      <Link to={url} style={{ textDecoration: "none" }}>
        {children}
      </Link>
    )
  }
}

export const PlanetSwiper = ({ controller, onSwiper }) => {
  const [linkUrl, setLinkUrl] = React.useState(null)

  return (
    <>
      <Swiper
        parallax={true}
        loop
        navigation
        loopAdditionalSlides={3}
        slidesPerView={1}
        controller={controller}
        onSwiper={onSwiper}
        onSlideChange={evt => {
          if (
            navigation[Object.keys(navigation)[evt.activeIndex - 4]]?.[
              getLangFromPath()
            ].externalUrl
          ) {
            setLinkUrl(
              navigation[Object.keys(navigation)[evt.activeIndex - 4]]?.[
                getLangFromPath()
              ].externalUrl
            )
          } else {
            setLinkUrl(
              navigation[Object.keys(navigation)[evt.activeIndex - 4]]?.[
                getLangFromPath()
              ].url
            )
          }
        }}
      >
        <div
          style={{
            height: "calc(var(--vh, 1vh) * 100)",
            width: "800vw",
            backgroundImage: "url(/textures/skybox_nx.jpg",
            position: "absolute",
            top: 0,
          }}
          data-swiper-parallax-x="-40%"
        />
        {Object.keys(navigation).map(planet => (
          <SwiperSlide key={planet}>
            <LinkOrA url={linkUrl}>
              <Box
                height="calc(var(--vh, 1vh) * 100);"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                {isPlanetImage(planet) && (
                  <S.StyledPlanet src={`/${navigation[planet].mobilePlanet}`} />
                )}
                <Box
                  mt={
                    isPlanetImage(planet)
                      ? theme.textStyles.mobilePlanetLabel.fontSize
                      : 0
                  }
                  mb={isPlanetImage(planet) ? 0 : 40}
                >
                  <Typography variant="mobilePlanetLabel" color="neutral.light">
                    {navigation[planet][getLangFromPath()]?.label}
                  </Typography>
                </Box>
              </Box>
            </LinkOrA>
          </SwiperSlide>
        ))}
      </Swiper>
      <S.DashedOutline radius={60} />
      <S.DashedOutline radius={28} style={{ top: "80%" }} />
      <LinkOrA url={linkUrl}>
        <Box
          position="absolute"
          top="80%"
          left="50%"
          borderRadius="50%"
          backgroundColor="text.secondary"
          width="calc(var(--vh, 1vh) * 11);"
          height="calc(var(--vh, 1vh) * 11);"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex={1}
          style={{
            transform: "translate3d(-50%,-50%,0)",
            boxShadow: `0px 0px 5px ${theme.palette.red}`,
          }}
        >
          <img src="/slider_right.svg" />
          <img src="/slider_right.svg" />
        </Box>
      </LinkOrA>
    </>
  )
}

export default PlanetSwiper
